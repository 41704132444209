<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          ทำรายการโยกเงินออก
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">จำนวนเงิน</label>
                  <input type="text" v-model="transfer.amount" class="form-control"  maxlength="10" placeholder="ข้อมูลจำนวนเงิน" style="text-align:right;">
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">ธนาคารต้นทาง</label>
                  <v-select v-on:input="onChange" v-model="selected_bank" :options="option_bank" label="title" combobox-class="form-control" placeholder="กรุณาเลือกธนาคารต้นทาง">
                    <template #option="{ title }">
                      {{ title }}
                    </template>
                  </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">เลขบัญชีต้นทาง</label>
                  <input type="number" v-model="transfer.bank_number" class="form-control"  placeholder="ข้อมูลเลขบัญชีต้นทาง" disabled>
              </b-form-group>
            </b-col>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">ชื่อบัญชีต้นทาง</label>
                  <input type="text" v-model="transfer.bank_acc_name" class="form-control"  placeholder="ข้อมูลชื่อบัญชีต้นทาง" disabled>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">ธนาคารปลายทาง</label>
                  <v-select v-model="selected_to_bank" :options="option_to_bank" label="title" combobox-class="form-control" placeholder="กรุณาเลือกธนาคารปลายทาง">
                    <template #option="{ title }">
                      {{ title }}
                    </template>
                  </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">เลขบัญชีปลายทาง</label>
                  <input type="number" v-model="transfer.bank_number_to" class="form-control"  placeholder="ข้อมูลเลขบัญชีปลายทาง">
              </b-form-group>
            </b-col>
            <b-col cols="md-4 col-sm-4">
              <b-form-group>
                  <label class="control-label">ชื่อบัญชีปลายทาง</label>
                  <input type="text" v-model="transfer.bank_acc_name_to" class="form-control"  placeholder="ข้อมูลชื่อบัญชีปลายทาง">
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <button @click="submit_transfer()" type="button" class="btn bg-gradient-success waves-effect waves-light text-white"><feather-icon icon="CreditCardIcon" class="font-medium-1 mr-1" />โยกเงินออก</button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการโยกเงินออก
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-9" class="pr-1 pl-1 mb-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-3">
              <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light mr-1"> ค้นหา </b-button>
              <b-button @click="this.filter=''" variant="btn-primary" class="btn bg-gradient-warning waves-effect waves-light"> ออกรานงาน Excel </b-button>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(amount)="data">
                  {{ func.toMoney(data.value) }}
                </template>
                <template #cell(bank)="data">
                  <div style="width: 200px">
                    {{ (typeof(bank_list[data.value]) != "undefined" ? bank_list[data.value] : data.value)+(data.item.bank_number == null ? '' : ' | '+data.item.bank_number) }} <br />  {{ (data.item.bank_acc_name == null ? '' : data.item.bank_acc_name) }}
                  </div>
                </template>
                <template #cell(bank_to)="data">
                  <div style="width: 200px">
                    {{ (typeof(bank_code_list[data.value]) != "undefined" ? bank_code_list[data.value] :  "-")+(data.item.bank_number_to == null ? '' : ' | '+data.item.bank_number_to) }} <br /> {{ (data.item.bank_acc_name_to == null ? '' : '  '+data.item.bank_acc_name_to) }}
                  </div>
                </template>
                <template #cell(status)="data">
                  <strong v-if="data.value == '1'" class='text-success'>สำเร็จ</strong>
                  <strong v-else-if="data.value == '2'" class='text-danger'>ไม่สำเร็จ</strong>
                  <strong v-else class='text-warning'>ระหว่างดำเนินการ</strong>
                </template>
                <template #cell(description)="data">
                  <div class="jumbotron p-1 mt-2" style="width: 200px">
                    <strong v-if="data.item.status == '2'" class='text-danger'>{{ data.value }}</strong>
                    <strong v-else >{{ data.value }}</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
  BCollapse, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable, BCollapse,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    vSelect,
    flatPickr,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      date_start_report: '',
      date_end_report: '',
      selected_bank: { title: 'กรุณาเลือกธนาคารต้นทาง', value: '' },
      option_bank: [
        { title: 'กรุณาเลือกธนาคารต้นทาง', value: '' },
      ],
      selected_to_bank: { title: 'กรุณาเลือกธนาคารปลายทาง', value: '' },
      option_to_bank: [
        { title: 'กรุณาเลือกธนาคารปลายทาง', value: '' },
        { title: 'ธนาคารกรุงเทพ : BBL', value: '002' },
        { title: 'ธนาคารกสิกรไทย : KBANK', value: '004' },
        { title: 'ธนาคารกรุงไทย : KTB', value: '006' },
        { title: 'ธนาคารทหารไทย : TMB', value: '011' },
        { title: 'ธนาคารไทยพาณิชย์ : SCB', value: '014' },
        { title: 'ธนาคารกรุงศรีอยุธยา : BAY', value: '025' },
        { title: 'ธนาคารออมสิน : GSB', value: '030' },
        { title: 'ธนาคารธนชาติ : TBANK', value: '065' },
        { title: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร : BAAC', value: '034' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      filter: '',
      filterOn: [],
      fields: [
        { key: 'id', label: '#', class: 'text-center' },
        { key: 'amount', label: 'จำนวนเงิน', class: 'text-right text-nowrap' },
        { key: 'bank', label: 'ธนาคารต้นทาง', class: 'text-center text-nowrap' },
        { key: 'bank_to', label: 'ธนาคารปลายทาง', class: 'text-center text-nowrap' },
        { key: 'status', label: 'สถานะ', class: 'text-center text-nowrap' },
        { key: 'created_at', label: 'วันเวลา', class: 'text-center text-nowrap' },
        { key: 'description', label: 'รายละเอียด', class: 'text-center' },
        { key: 'admin_full_name', label: 'ทำรายการโดย', class: 'text-center' },
      ],
      items: [],
      t_fetch: 0,
      transfer: {
        bank_id: '',
        bank: '',
        bank_number: '',
        bank_acc_name: '',
        amount: '',
        bank_to: '',
        bank_number_to: '',
        bank_acc_name_to: '',
      },
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
      bank_code_list: JSON.parse('{"002":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","004":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","006":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","011":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","014":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","025":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","030":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","065":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","034":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.fetch_bank()
    this.t_fetch = setInterval(() => {
      if (this.currentPage != 1) return;
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    onFiltered(filteredItems) {
      if (this.filter != '' && this.filter.startsWith('status:')) {
        this.filter = ''
      }
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    loading() {
      this.$swal({
			  text: "กรุณารอสักครู่..",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: '',
        onBeforeOpen: () => {
          this.$swal.showLoading()
        },
      });
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
    },
    fetch(start=0) {
      let url = `LogTransferOut/log_transfer_out_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    fetch_bank() {
      this.$http.get('bank/bank_list').then(res => { res.data.result.forEach(data=> this.option_bank.push({title: `${data.bank_number} : ${data.account_name}`, value: data.id, name: data.account_name, number: data.bank_number, code: data.bank_code })) })
    },
    onChange() {
      let { code, name, number, value } = this.selected_bank
      this.transfer.bank = code
      this.transfer.bank_acc_name = name
      this.transfer.bank_number = number
      this.transfer.bank_id = value
    },
    submit_transfer() {
      this.transfer.bank_to = this.selected_to_bank.value
      if (this.transfer.amount.toString().trim().length == 0) {
        return this.warn('กรุณาระบุจำนวนเงิน');
      }
      if (this.transfer.amount == 0) {
        return this.warn('จำนวนเงินต้องมากกว่า 0');
      }
      if (this.transfer.bank_id.toString().trim().length == "") {
        return this.warn('กรุณาเลือกธนาคารต้นทาง');
      }
      if (this.transfer.bank_to.toString().trim().length == "") {
        return this.warn('กรุณาเลือกธนาคารปลายทาง');
      }
      if (this.transfer.bank_number_to.toString().trim().length == "") {
        return this.warn('กรุณาระบุเลขบัญชีปลายทาง');
      }
      if (this.transfer.bank_acc_name_to.toString().trim().length == "") {
        return this.warn('กรุณาระบุชื่อบัญชีปลายทาง');
      }
      this.$swal({
        title: 'ตรวจสอบ',
        html: 'ยืนยันการโยกเงินออก ' + this.func.toMoney(this.transfer.amount) + ' ฿<br>ชื่อบัญชี ' + this.transfer.bank_acc_name_to + ' <br>เลขบัญชี ' + this.transfer.bank_number_to,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
		    cancelButtonColor: 'red',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger mr-1',
        },
        buttonsStyling: false,
		    reverseButtons: true,
      }).then(result => {
        if (result.value) {
          this.loading()
          this.$http.post('TransferOut/transfer_out_money', this.transfer).then(res => {
            this.$swal.close()
            if (res.data.result) {
              this.fetch()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ทำรายการสำเร็จ',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'โยกเงิน ไปยังบัญชี : '+this.transfer.bank_acc_name_to+' จำนวน '+this.func.toMoney(Number(this.transfer.amount))+' บาท',
                },
              })
              this.transfer.amount = '';
            } else {
              this.warn(res.data.message)
            }
          }).catch((error) => {
            this.$swal.close()
            this.warn('ทำรายการไม่สำเร็จ error:'+error)
          })
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
